import React, { useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import axios from "axios";

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-24`}
`
const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`

class CForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      message: ''
    }
  }

  handleSubmit(e){
    e.preventDefault();
    axios({
      method: "POST",
      url:"https://4fkqq7adx3.execute-api.us-east-1.amazonaws.com/prod/contact-us",
      headers: {
        "Content-Type": "application/json"
      },
      data:  this.state
    }).then((response)=>{
      if (response.data.status === 'success') {
        alert("Message Sent.");
        this.resetForm()
      } else if (response.data.status === 'fail') {
        alert("Message failed to send.")
      }
    })
    this.setState({
      showSuccessBlock: true
    })
  }

  resetForm(){
    this.setState({name: '', email: '', message: ''})
  }

  render() {
    return(
      this.state.showSuccessBlock ?
      <div className="contactsuccess" data-tw="text-primary-500">
        Thank you!
      </div> :
      <div className="App">
        <Form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST">
              {/* <label htmlFor="name">Name</label> */}
              <Input 
                type="text" 
                id="name"
                name="name"
                placeholder="Name" 
                value={this.state.name} onChange={this.onNameChange.bind(this)} 
              />
              {/* <label htmlFor="exampleInputEmail1">Email address</label> */}
              <Input 
                  type="email" 
                  id="email" 
                  name="email"
                  aria-describedby="emailHelp" 
                  placeholder="Your Email Address" 
                  value={this.state.email} onChange={this.onEmailChange.bind(this)} 
                />
              {/* <label htmlFor="message">Message</label> */}
              <Textarea 
                rows="5" 
                id="message" 
                name="message"
                placeholder="Your Message Here"
                value={this.state.message} onChange={this.onMessageChange.bind(this)} 
              />
          <SubmitButton type="submit" className="btn btn-primary contactitem">
            Submit
          </SubmitButton>
        </Form>
      </div>
    );
  }

  onNameChange(event) {
	  this.setState({name: event.target.value})
  }

  onEmailChange(event) {
	  this.setState({email: event.target.value})
  }

  onMessageChange(event) {
	  this.setState({message: event.target.value})
  }
}

export default CForm;