import React from "react";
import axios from "axios";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/microphone.png";
import CForm from "components/forms/Form.js";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-24`}
`

	// Use your API endpoint URL you copied from the previous step
// const endpoint =
//     "<https://93xf4mmjs6.execute-api.us-east-1.amazonaws.com/default/SendContactEmail>";
  // We use JSON.stringify here so the data can be sent as a string via HTTP

//const [values, setValues] = useState({name: '', message: '', email: ''})
//const [sent, setSent] = useState(false);
// const handleChange = e => {
//       const {name, value} = e.target
//       setValues({...values, [name]: value})
//   };

// async function handleSubmit(event) {
//       event.preventDefault();
//       const response=await axios.post(
//           'https://93xf4mmjs6.execute-api.us-east-1.amazonaws.com/default/SendContactEmail',
//           { message: `${name}`, name: `${message}`, email: `${email}` }
//       );
//       console.log(response);
//       setSent(true);
//       };


const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`

export default ({
  subheading = "Contact Us",
  heading = <>Any questions? <span tw="text-primary-500">Get in touch</span><wbr/> with us.</>,
  description = "Email us at contact@metabetta.com, or fill in the form below and we'll be in touch promptly.", //"Fill out the form below and we'll get in touch with you.",
  submitButtonText = "Send",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  return (
    <Container id="contact">
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
              <CForm />
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
