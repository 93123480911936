import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import logo from "../../images/mb-m-500-white.png";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

const Container = tw.div`my-8 lg:my-10 bg-primary-900 text-gray-100 -mx-8 px-10 pb-10`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto pt-20 md:pt-24`}
`;
//const Subheading = tw(SubheadingBase)`mb-4 text-gray-900`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center text-gray-100 pb-10`;
const LogoImg = tw.img`w-8`;
//const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-xs`}
`;

const Card = styled.div`
  ${tw`flex flex-col items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`bg-gray-100 text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`mt-6`}
  }

  .title {
    ${tw`tracking-wider font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-2 font-normal text-gray-100 leading-snug`}
  }
`;

export default ({
  cards = null,
  heading = "Music Industry Data",
  // subheading = "Metabetta",
  description = "As well as offering consultancy, we also develop our own proprietary software and run our own database organising, compiling and deduplicating all official music data sources for the music rights management market."
}) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  return (
    <Container
      id="music"
    >
      <ThreeColumnContainer>
        {/* {subheading && <Subheading className="mblogo">{subheading}</Subheading>} */}
        <LogoImg src={logo} />
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
      </ThreeColumnContainer>
    </Container>
  );
};
